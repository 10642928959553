<template>
  <div style="width: 100%" class="social-media-group-page">
    <a-row justify="space-between" align="middle" class="my-30" :gutter="25">
      <a-col :xs="10" :md="3" class="text-group">
        <span class="group">Group List </span><span class="sub">({{ pagination.total }})</span>
      </a-col>
      <a-col :md="1" class="space-group"></a-col>
      <a-col :xs="13" :md="20" class="search-group">
        <a-input
          v-model:value="groupSearch"
          placeholder="Search Group Name"
          class="rounded input-searchgroup"
          @keyup.enter="onSearch"
        >
          <template #suffix>
            <FeatherIcon class="icon-search" type="search" />
          </template>
        </a-input>

        <a-button type="primary" @click="createVisible = true"
          ><span class="display-mobile">+</span><span class="display-desktop">Create New Group</span></a-button
        >
      </a-col>
    </a-row>

    <div v-if="!groups.length">
      <NoDataFound></NoDataFound>
    </div>
    <a-card v-for="group in groups" v-else :key="group.name + group.source" :title="group.name" class="card-source-box">
      <template #extra>
        <a-dropdown>
          <a class="ant-dropdown-link" @click.stop="">
            <FeatherIcon type="more-horizontal"></FeatherIcon>
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item class="edit-media-account" @click="onEdit(group)">
                <a href="javascript:;"><FeatherIcon type="edit"></FeatherIcon> Edit</a>
              </a-menu-item>
              <a-menu-item class="del-media-account">
                <a href="javascript:;" @click="(confirmVisible = true), (selectedData = group)"
                  ><FeatherIcon type="trash-2"></FeatherIcon> Delete</a
                >
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </template>
      <div class="source-box-wrapper">
        <div v-for="media in group.medias" :key="media.name + media.source" class="source-box-p">
          <SourceBox class="div-gray" :source="media"></SourceBox>
        </div>
      </div>
    </a-card>

    <CreateGroup :visible="createVisible" @close="createVisible = false" @success="getGroups"></CreateGroup>
    <EditGroup
      :visible="editVisible"
      :data="selectedData"
      @close="editVisible = false"
      @success="getGroups"
    ></EditGroup>

    <ConfirmDelete :visible="confirmVisible" @close="confirmVisible = false" @confirm="deleteGroup">
      Are you sure to delete this group ? Group and all social media accounts will be deleted permanantly
    </ConfirmDelete>
    <div class="text-center">
      <a-pagination
        v-if="pagination.total"
        v-model:current="pagination.currentPage"
        v-model:pageSize="pagination.limit"
        :hide-one-single-page="true"
        show-size-changer
        :default-current="1"
        :total="pagination.total"
        @showSizeChange="onPageSizeChange"
        @change="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import SourceBox from './SourceBox.vue';
import ConfirmDelete from '@/components/ConfirmDelete.vue';
import CreateGroup from './CreateGroup.vue';
import EditGroup from './EditGroup.vue';
import { ref, onMounted, reactive } from 'vue';
import NoDataFound from './NoDataFound.vue';
import api from '@/services/api';
import { notification } from 'ant-design-vue';
import _ from 'lodash';
import { useStore } from 'vuex';

export default {
  components: { SourceBox, ConfirmDelete, CreateGroup, EditGroup, NoDataFound },
  setup() {
    const store = useStore();
    const groupSearch = ref('');
    const createVisible = ref(false);
    const editVisible = ref(false);
    const confirmVisible = ref(false);
    const groups = ref([]);
    const selectedData = ref(null);

    const pagination = reactive({
      currentPage: 1,
      total: 0,
      skip: 0,
      limit: 10,
    });

    const onSearch = _.debounce(() => {
      pagination.currentPage = 1;
      pagination.skip = 0;
      pagination.limit = 10;
      pagination.total = 0;
      getGroups();
    }, 100);
    const onPageChange = (page, pageSize) => {
      let skip = page - 1;
      pagination.skip = skip;
      pagination.limit = pageSize;
      getGroups();
    };

    const onPageSizeChange = (page, pageSize) => {
      let skip = page - 1;
      pagination.skip = skip;
      pagination.limit = pageSize;
      getGroups();
    };

    const getGroups = async () => {
      let result = await api.getPaginateSocialMediaGroups(groupSearch.value, pagination.skip, pagination.limit);
      if (result.message) {
        pagination.total = result.message.total;
        groups.value = result.message.data.map((m) => {
          return {
            ...m,
            medias: m.medias.map((v) => {
              return {
                name: v.username,
                username: v.username,
                source: v.source,
                userId: v.userId,
                id: v.userId,
                value: v.userId,
                objId: v.id,
              };
            }),
          };
        });
        groups.value.sort((a, b) => a.name.localeCompare(b.name));
      }
      store.dispatch('filter/initFilter', true);
    };

    const deleteGroup = async () => {
      let result = await api.deleteSocialMediaGroup(selectedData.value.id);
      if (result.code === 200) {
        notification.success({
          message: 'Success',
          description: `Group ${selectedData.value.name} removed.`,
        });
      } else {
        notification.error({
          message: 'Error',
          description: `Group ${selectedData.value.name} remove failed.`,
        });
      }

      getGroups();

      confirmVisible.value = false;
    };

    // const filterGroups = computed(() => {
    //   if (groupSearch.value) {
    //     return groups.value.filter((v) => v.name.toLocaleLowerCase().includes(groupSearch.value.toLowerCase()));
    //   }
    //   return groups.value;
    // });

    const onEdit = (group) => {
      editVisible.value = true;
      selectedData.value = group;
    };

    onMounted(() => {
      getGroups();
      store.dispatch('socialMediaGroup/loadSocialMediaAccounts', { username: '' });
    });

    return {
      groupSearch,
      createVisible,
      confirmVisible,
      editVisible,
      groups,
      // filterGroups,

      selectedData,
      getGroups,
      deleteGroup,
      onEdit,

      onPageChange,
      pagination,
      onPageSizeChange,
      onSearch,
    };
  },
};
</script>

<style lang="scss">
.social-media-group-page {
  padding: 24px 30px 30px 30px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}
.sub {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: #5a5f7d;
}
.mr-24 {
  margin-right: 24px;
}
.my-30 {
  margin-bottom: 30px;
}
.rounded {
  border-radius: 24px;
}
.mb-0 {
  margin-bottom: 0px;
}

.div-gray {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  align-items: center;
  padding: 8px;
  background-color: #f8f9fb;
  border-radius: 5px;
}

.source-box-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.source-box-p {
  padding: 8px;
}

li.edit-media-account:hover,
li.del-media-account:hover {
  background: rgba(51, 113, 255, 0.05);
}

li.edit-media-account span a:hover,
li.del-media-account span a:hover {
  color: #3371ff;
}

.card-source-box .ant-card-body {
  padding: 16px 25px 16px 25px;
}

.card-source-box .ant-card-head-wrapper {
  flex-flow: unset;
}

.card-source-box .ant-card-head-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 10px !important;
  height: 68px;
}

.search-group {
  justify-content: space-between;
  display: flex;
  padding-right: 12.5px;
  padding-left: unset;
}

.input-searchgroup {
  width: 300px;
}

.group {
  color: #272b41;
  font-size: 16px;
  font-weight: 500;
}

.ant-card .ant-card-head .ant-card-head-title {
  padding: 22px 0;
  line-height: unset;
}

.icon-search {
  color: #adb4d2;
}

.text-group {
  padding-right: unset !important;
}

.search-group {
  padding-left: unset !important;
}

@media screen and (max-width: 476px) {
  span.display-mobile {
    display: block;
  }
  span.display-desktop {
    display: none;
  }
}

@media screen and (min-width: 477px) {
  span.display-mobile {
    display: none;
  }
  span.display-desktop {
    display: block;
  }
}

@media screen and (max-width: 575px) {
  .social-media-group-page {
    padding: unset;
    overflow: hidden;
  }
  .ant-card-head-wrapper .ant-card-extra {
    padding: unset !important;
  }
  .input-searchgroup {
    width: 135px;
    margin-right: 10px;
  }
  .ant-card .ant-card-head .ant-card-head-title {
    padding: 21px 0;
    line-height: 1.4;
  }
  .search-group {
    padding-left: unset !important;
  }
  .space-group {
    display: none;
  }
  .my-30 {
    margin-top: 24px;
  }
}
</style>
