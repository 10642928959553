<template>
  <a-modal centered :width="520" :footer="null" :closable="false" @cancel="closeConfirm">
    <div>
      <a-row>
        <a-col span="2">
          <FeatherIcon type="x-circle" size="18" class="color-danger" />
        </a-col>
        <a-col span="22">
          <div class="title">Confirm Delete</div>
          <div class="ask-text">
            <slot></slot>
          </div>
          <div class="action-button-modal text-right">
            <a-button size="large" type="default" class="cancel-button mr-2" :outlined="true" @click="closeConfirm">
              Cancel
            </a-button>
            <a-button size="large" type="default" class="delete-button" @click="confirmDelete">Delete</a-button>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
export default {
  emits: ['close', 'confirm'],
  setup(props, context) {
    const confirmDelete = () => {
      context.emit('confirm');
    };
    const closeConfirm = () => {
      context.emit('close');
    };
    return { closeConfirm, confirmDelete };
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-family: DM Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 20px;
}
.delete-button,
.cancel-button {
  border-radius: 4px;
  font-family: DM Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}
.delete-button {
  background: #ff4d4f;
  color: white;
}

.cancel-button {
  background: #f4f5f7;
  color: #5a5f7d;
}

.ask-text {
  margin-bottom: 42px;
}
</style>
